export default {
    async setDetailData(data){
     
        const localePath = useLocalePath();
        const router = useRouter()
        const route = useRoute()

        this.commitMutation('resetWebshopDetail')

        try{
            const res = await $fetch(useCountryPath("/config/shop/detail/" + data.slug));
            // const res = await response.json();

            if (!res.success) {
                throw error(  { message :'missing data property in response setDetailData webshop', status : 500}  )
            }
            if ( res.data.full_slug == '' ) {
                if (import.meta.client) {
                    return router.push(localePath({name: 'shop-all'}));
                }
    
                if ( import.meta.server) {
                    return navigateTo( localePath('/wedding-rings/anniversary-rings'), 302)
                }
            }
            const editedPath = data.fullPath.replace('/in/', '/')
            
            if (res.data.full_slug !== editedPath) {
                if (import.meta.client) {
                    return router.push( localePath(res.data.full_slug) )
                }
    
                if ( import.meta.server) {
                    return navigateTo(localePath(res.data.full_slug), 302)
                }
            }
            
            await this.commitMutation('setDetailData',{ data: res.data, fastShip: data.fastShip})
            
            await this.setSizeData({ slug: data.slug, fastShip: data.fastShip })
            
            await this.setInstockData({ slug: data.slug })
            
            return res.data
           
          
        }catch(error){
            console.log(error, 'error')
            if (error.response?.status === 404 || error.response?.status === 400) {
                const redObj = {
                    'hoop-earrings': 'shop-hoop',
                    'tennis-bracelet': 'shop-bracelet',
                    'anniversary-rings': 'shop-anniversary',
                    'couple-rings': 'shop-couple-rings',
                    'eternity-rings': 'shop-eternity',
                    'womens-wedding-rings': 'shop-womens-wedding-rings',
                    'solitaire-necklace': 'shop-solitaire-necklace',
                    'stud-earrings': 'shop-earring',
                    'tennis-necklace': 'shop-tennis-necklace',
                    'toi-et-moi-engagement-rings': 'toi-et-moi-ring',
                    'ready-to-ship-engagement-rings': 'instock_ring',
                }
                if(redObj[route.params.type]){
                    navigateTo(localePath({ name: redObj[route.params.type]}))
                }
                else{
                    navigateTo(localePath('/'))
                }

            }

        }
       
    },

    async setInstockData(data){
      
        try{

            const res = await $fetch(useCountryPath("/shop/in-stock/" + data.slug));
            // const res = await response.json();
            if(res.success){
                // console.log(res, 'res.datares.datares.data')
                this.commitMutation( 'setInstockData', res.data )
                this.commitMutation( 'setInStockMetals', res.data )
                return res.data
            }
        
        }catch(error){
            throw error
            // if (error.response?.status === 404 || error.response?.status === 400) {
            //     // check if redirection exist
            // }
            // // console
            // console.log('eerror', error)
            // throw new Error(error.message || 'Failed to fetch in-stock data');
        }
       
    },

    async setSizeData(data){
        
        try{
            const res = await $fetch(useCountryPath("/config/shop/size/detail/" + data.slug));
            // const res = await response.json();
            if(res.success){
               
                this.commitMutation('setSizeData',{sizeData : res.data, data:data})
                return res.data
            }
        
        }catch(error){
            throw error
            if (error.response?.status === 404 || error.response?.status === 400) {
                // check if redirection exist
            }
            // console
            return null

            // throw error
        }
       
    },
   
    async setAdditionalDetails(data){
        
        try{
            // console.log( 'this.route.slug', this.$route.params.slug )
            const res = await $fetch(useCountryPath("/config/shop/attribute/detail/" + data.slug));
            // const res = await response.json();

            if(res.success){
                this.commitMutation('setAdditionalDetails',res.data)
                return res.data
            }
        
        }catch(error){
            throw error
            if (error.response?.status === 404 || error.response?.status === 400) {
                // check if redirection exist
            }
            // console
            return null

            // throw error
        }
       
    },

    async setActive(data){
        const router = useRouter()
        await this.commitMutation('setActive', data)
        //get fastship from route
        let fastShip = false
        console.log(router.currentRoute.value.query.FastShip, 'setActivesetActivesetActive')
        // return
        if(router.currentRoute.value.query?.FastShip){
            fastShip = true
        }

        //if carat and metal toggeled call setSize and setAttribute again
        if(['carat', 'metal'].includes(data.type) && !fastShip){
            const slug = this.getCurrentActive('carat').slug
            await this.setSizeData({ slug: slug, fastShip: fastShip })
            await this.setAdditionalDetails({ slug: slug, fastShip: fastShip })
        }
        
    }
}